import React, { ReactElement } from "react";
import styles from "./style.module.scss";
import Image from "next/image";

export default function ToxicChemicals(): ReactElement {
    return (
        <section
            className={`${styles["wrapper"]} px-3 flex flex-col lg:flex-row  mt-36 mb-32 gap-[50px]`}
        >
            <div
                className={`sm:w-[573px] sm:min-w-[573px] sm:h-[446px] w-[300px] h-[200px] relative  mx-auto lg:mx-0 `}
            >
                <Image
                    src={
                        "/basecamplejeune/assets/images/toxicChemicalsImage.jpg"
                    }
                    layout="fill"
                    alt="Image"
                />
            </div>
            <div className={`max-w-[575px]  mx-auto lg:mx-0 `}>
                <h2>Toxic Chemicals Found at Camp Lejeune</h2>

                <p className="font-light">
                    It was found that the water at Camp Lejeune had an unusually
                    high amount of toxic chemicals. Toxic chemicals such as
                    benzene, trichloroethylene (TCE), tetrachloroethylene,
                    perchloroethylene (PCE), and vinyl chloride are known
                    carcinogens to humans. There were many ways that these
                    toxins got into the water system, but the most common ones
                    were leaks from garbage dumps and underground storage tanks.
                    The tanks were mostly sealed off in February 1985, but those
                    subjected to the toxic chemicals are still dealing with
                    serious, life-threatening injuries from these carcinogens.
                </p>
            </div>
        </section>
    );
}
