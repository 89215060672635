import React, { ReactElement } from "react";
import MainBanner from "@components/basecamplejeune/mainBanner";
import LejeuneCampLejeuneWrapper from "@components/layouts/basecamplejeuneWrapper";
import { GetServerSideProps } from "next";
import dynamic from "next/dynamic";
import { getPageProps } from "@services/initial-calls";

import { useDomainContext } from "@hooks/useDomainContext";
import ToxicChemicals from "@components/basecamplejeune/toxicChemicals";
const OffersList = dynamic(() => import("@components/shared/offersList"));
const Case = dynamic(() => import("@components/basecamplejeune/case"));
const WhyUs = dynamic(() => import("@components/basecamplejeune/whyUs"));
const ToxicWaste = dynamic(
    () => import("@components/basecamplejeune/toxicWaste"),
);

export default function CampLejeuneApplicationHome(): ReactElement {
    const { domain } = useDomainContext();

    return (
        <LejeuneCampLejeuneWrapper>
            <MainBanner />

            <ToxicWaste />
            <section>
                <OffersList offers={domain?.defaultOffers} />
            </section>
            <WhyUs />
            <ToxicChemicals />
            <Case />
        </LejeuneCampLejeuneWrapper>
    );
}

export const getServerSideProps: GetServerSideProps = async (context) => {
    return await getPageProps(context, "home");
};
